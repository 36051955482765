import axios from '@axios';

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchReports(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/report', { params: queryParams })
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    fetchReport(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/api/report/${id}`)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    updateReport(ctx, { reportData, id }) {
      return new Promise((resolve, reject) => {
        axios
          .put(`/api/report/${id}`, reportData)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    deleteReport(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/api/report/${id}`)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    addReport(ctx, reportData) {
      return new Promise((resolve, reject) => {
        axios
          .post('/api/report', reportData)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    fetchStyles(ctx) {
      return new Promise((resolve, reject) => {
        axios
          .get('api/report/style')
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    fetchHeaders(ctx) {
      return new Promise((resolve, reject) => {
        axios
          .get('api/report/header')
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    fetchFooters(ctx) {
      return new Promise((resolve, reject) => {
        axios
          .get('api/report/footer')
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    fetchLayouts(ctx) {
      return new Promise((resolve, reject) => {
        axios
          .get('api/layout')
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    fetchClients(ctx) {
      return new Promise((resolve, reject) => {
        axios
          .get('api/client')
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    fetchCompanyHeaders(ctx) {
      return new Promise((resolve, reject) => {
        axios
          .get('api/report/company/header')
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    fetchCompanyFooters(ctx) {
      return new Promise((resolve, reject) => {
        axios
          .get('api/report/company/footer')
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
  },
};
